import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput,
} from "reactstrap";
import Loader from "../../../shared/Loader/Loader";
import writeXlsxFile from "write-excel-file";
import readXlsxFile from "read-excel-file";
import { ToastContainer, toast } from "react-toastify";
import { get, post, put } from "../../../../types/api";

type UploadActivityModalProps = {
  isOpen: any;
  toggle: any;
  serviceTicketId: any;
  tokenDetails: any;
  timecardId: any;
  getServiceTicketId: any;
  serviceTicketDetails: any;
  existingServiceContractItemIds: any;
  selectedServiceTicketsDetails: any;
  activeTab: any;
  getServiceTicketDetails: any;
};
function UploadActivityModal({
  isOpen,
  toggle,
  serviceTicketId,
  tokenDetails,
  timecardId,
  getServiceTicketId,
  serviceTicketDetails,
  existingServiceContractItemIds,
  selectedServiceTicketsDetails,
  activeTab,
  getServiceTicketDetails,
}: UploadActivityModalProps) {
  const [loading, setLoading] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState([] as any);
  const [pageLoader, setPageLoader] = useState(false);

  // Download excel file template
  const downloadTemplate = async () => {
    setLoading(true);
    setLoading(false);
    // validation
    const dynamicHeaders = [] as any;
    const emptyObjects = [] as any;
    let HEADER_ROW = ["Pay Item"] as any;
    // handle header for dashboard -> service ticket flow
    if (serviceTicketId) {
      serviceTicketDetails.stActivityListHeaders.forEach(
        (e: any, index: number) => {
          if (e.isActive === 1) {
            dynamicHeaders.push(e.fieldName);
            emptyObjects.push({});
          }
        }
      );
      HEADER_ROW.push(...dynamicHeaders, "Estimated Quantity");
    }
    // handle header for dashboard -> timecard -> service ticket flow
    if (!serviceTicketId) {
      HEADER_ROW.push("Actual Quantity");
    }
    HEADER_ROW = HEADER_ROW.map((e: any) => ({
      value: e,
      fontWeight: "bold",
    }));
    let fileName = "";
    if (serviceTicketId) {
      fileName = "Service Ticket Activities.xlsx";
    } else {
      fileName = "Service Ticket Details.xlsx";
    }
    await writeXlsxFile([HEADER_ROW], {
      fileName,
    });
  };

  // API : Get service contract activity list
  const getServiceContractActivity = async () => {
    try {
      const url = `/v1/api/service/contract/id/${serviceTicketDetails.serviceContract?.id}/setup`;
      const { data } = await get(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  // Prepare payload and upload file data
  const handleUploadFile = async () => {
    try {
      setLoading(true);
      const dynamicHeaders = [] as any;
      // if routing from service ticket then add dynamic fields to validate file header
      if (serviceTicketId) {
        serviceTicketDetails.stActivityListHeaders.forEach((e: any) => {
          if (e.isActive === 1) {
            dynamicHeaders.push(e);
          }
        });
      }
      //validate headers
      const isValid = validateFileHeader(dynamicHeaders);
      if (isValid) {
        setPageLoader(true);
        const sQuantities = [] as any;
        let invalidPayItems = "" as any;
        // uploaded data 
        if(selectedFileData.length) {
          // API Call - get activity list
          const contractSetupDetails = await getServiceContractActivity();
          // iterate through file uploaded data
          for (let index = 0; index < selectedFileData.length; index++) {
            const row = selectedFileData[index];
            // start from first row and row must have value
            if (index > 0 && row[0]) {
              let rowServiceTicketDetails = {} as any;
              // from activity list of service contract, find current row activity
              rowServiceTicketDetails = contractSetupDetails.activityList.find(
                (e: any) => e.payItem === row[0].toString()
              );
              // rowServiceTicketDetails should be empty if invalid Pay Item value entered
              if(!rowServiceTicketDetails?.id) {
                invalidPayItems += ' ' + row[0] + ',';
                continue;
              }
              // check if activity item already selected then continue to next item
              if (existingServiceContractItemIds.indexOf(rowServiceTicketDetails.id) >= 0) {
                continue;
              }
              let dynamicHeadersData = [] as any;
              const dynamicItemsData = [] as any;
              // format dynamic fields data uploaded from file
              if (serviceTicketId) {
                dynamicHeadersData = row.slice(1, row.length - 1);
                dynamicHeaders.forEach((e: any, index: number) => {
                  const fvalue = dynamicHeadersData[index];
                  if (fvalue) {
                    dynamicItemsData.push({
                      serviceItemFieldValue: fvalue.toString(),
                      customFieldIndex: e.customFieldIndex,
                    });
                  }
                });
              }
              // prepare payload
              let data = {
                id: 0,
                tenantId: tokenDetails?.tenantId,
                serviceContractItemId: rowServiceTicketDetails?.id,
                serviceTicketId:
                  serviceTicketId ||
                  selectedServiceTicketsDetails.serviceTicketsId[activeTab - 1],
                activityList: [] as any,
                fields: dynamicItemsData || [],
                changeNote: "",
              } as any;
              // add estimated quantity
              if (!serviceTicketId) {
                if (isNaN(row[row.length - 1])) {
                  errorAlert("Actual quantity must be number.");
                  break;
                }
                data = {
                  ...data,
                  estimatedQuantity: 0,
                };
              } else {
                if (isNaN(row[row.length - 1])) {
                  errorAlert("Estimated quantity must be number.");
                  break;
                }
                data = {
                  ...data,
                  estimatedQuantity: Number(row[row.length - 1]),
                };
              }
              // create ticket activity
              const apiURL = `/v1/api/service/ticket/activity`;
              // API call
              await post(apiURL, data)
                .then(async (res: any) => {
                  // store service quantity values
                  if (!serviceTicketId) {
                    const currActivity = res.data.activityList.find(
                      (e: any) =>
                        e.serviceContractItemId === rowServiceTicketDetails?.id
                    );
                    sQuantities.push({
                      serviceTicketItemId: currActivity.id,
                      quantity: row[row.length - 1],
                    });
                  }
                  // handle alert, loader and modal close on last api call
                  if (
                    !sQuantities.length &&
                    index === selectedFileData.length - 1
                  ) {
                    if (res.status && res.status === 200) {
                      toggle();
                      toast.success("Successfully activities updated", {
                        autoClose: 2000,
                      });
                    } else {
                      errorAlert();
                    }
                    setPageLoader(false);
                    getServiceTicketDetails();
                  }
                })
                .catch((error) => {
                  if (index === selectedFileData.length - 1) {
                    setPageLoader(false);
                  }
                  errorAlert(
                    JSON.stringify(
                      error.response.data.message[0].message || error.message
                    )
                  );
                });
            }
          }
        }

        // update service quantity for activity
        if (sQuantities.length) {
          const activityList = await getSTActivityItems();
          sQuantities.forEach((e: any, index: number) => {
            const foundActivity = activityList.find(
              (activity: any) => activity.id === e.serviceTicketItemId
            );
            // API call - update quantity
            updateServiceQty(e.quantity, foundActivity);
            if (index === sQuantities.length - 1) {
              toggle();
              toast.success("Successfully activities updated", {
                autoClose: 2000,
              });
              setPageLoader(false);
              getServiceTicketDetails();
            }
          });
        }
        if(invalidPayItems.length) {
          const invalidItems = invalidPayItems.substring(0, invalidPayItems.length - 1);
          errorAlert("Following Pay Item's is invalid:" + invalidItems + '.', 15000);
          setPageLoader(false);
        }
      }
    } catch (error: any) {
      console.log("error in file upload: ", error);
    } finally {
      setLoading(false);
    }
  };

  // API call - update quantity
  const updateServiceQty = async (sQuantity: any, currActivity: any) => {
    const url = `/v1/api/service/ticket/item-activity`;
    // prepare payload
    let payload = {
      id: currActivity.serviceItemActivityId,
      tenantId: tokenDetails?.tenantId,
      serviceTicketItemId: currActivity.id,
      timeCardId: Number(timecardId),
      serviceQty: sQuantity,
      serviceHours: currActivity.unitHours * sQuantity,
    };
    // setPageLoader(true);
    const res = await put(url, payload);
    return res;
  };

  //API: Get service ticket activity items
  const getSTActivityItems = async () => {
    let serviceTicketId = getServiceTicketId();
    const url = `/v1/api/service/ticket/item-activity?serviceTicketId=${serviceTicketId}&timeCardId=${timecardId}&tenantId=${tokenDetails?.tenantId}`;
    const { data } = await get(url);
    if (data && data.data && data.data.activityList) {
      return data.data.activityList;
    }
    return;
  };

  const errorAlert = (
    msg = "Something went wrong",
    autoClose = 5000 as any
  ) => {
    const config = {
      autoClose
    } as any;
    toast.warn(msg, config);
  };

  // file selected for upload
  const uploadActivityFile = async (event: any) => {
    console.log("uploadActivityFile");
    if (event.target.files[0]) {
      readXlsxFile(event.target.files[0])
        .then((rows) => {
          if (rows.length < 2) {
            errorAlert("File is empty.");
          } else {
            const data = rows.filter(
              (row) => row.filter((column) => column !== null).length > 0
            );
            setSelectedFileData(data);
          }
        })
        .catch((e) => {
          console.log("error in file upload: ", e);
          errorAlert("Found error in file upload. Please validate file type.");
        });
    }
  };

  // Validated uploaded file headers
  const validateFileHeader = (dynamicHeaders: any) => {
    let staticHeaders = 2;
    if (dynamicHeaders.length + staticHeaders === selectedFileData[0].length) {
      return true;
    }
    errorAlert(
      "File headers are not valid. Please download below template and validate"
    );
    return false;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        fade={false}
        toggle={toggle}
        className="download-docs-modal modal-md"
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ModalHeader
          toggle={() => {
            toggle();
          }}
        >
          Upload Activity Excel File
        </ModalHeader>
        <ModalBody>
          {(loading || pageLoader) && <Loader />}
          <div className="custom-control">
            <Button color="secondary" onClick={() => downloadTemplate()}>
              Download Activities Template
            </Button>
          </div>
          <div className="custom-control">
            <CustomInput
              type="file"
              id="input"
              name="customFile"
              label={"Choose an excel file"}
              onChange={uploadActivityFile}
            />
          </div>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleUploadFile()}>
              Upload
            </Button>{" "}
          </ModalFooter>
        </ModalBody>
      </Modal>
    </>
  );
}

export default UploadActivityModal;
