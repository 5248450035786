import React, { useState } from "react";
import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { CSVLink } from "react-csv";
import moment from "moment";
import Loader from "../../shared/Loader/Loader";


interface IHistoryTabProps {
    historyData: any;
    setHistoryData: (val: Array<object>) => any;
    compLoader: boolean;
}
const HistoryTab = (props: IHistoryTabProps) => {
    const [searchText, setSearchText] = useState('');

    const handleSearch = (text: string) => {
        setSearchText(text);
        let data = [];
        data = props.historyData.filter(
            (el: any) => {
                if (text === "") {
                    return el
                }
                else {
                    console.log(el.changeMessage)
                    return el.changeMessage.includes(text) || el.nameOfUser.includes(text);
                }
            }
        )
        props.setHistoryData(data);
    }

    return (
        <div className="rr-list-wrapper">
            {props.compLoader && <Loader showComponentLoader={true} />}
            <div className="rr-list-heading d-flex align-items-center justify-content-between">
                <h3 className="heading-sub">
                    <div className="table-search">
                        <input
                            className="table-search-field table-search-icon"
                            placeholder="Search" onChange={(e) => handleSearch(e.target.value)}
                            value={searchText}
                        ></input>
                        {/* eslint-disable-next-line */}
                        <a href="javascript:void(0)" className="table-search-close">
                            <Close />
                        </a>
                    </div>
                </h3>

                <div className="rr-list-btns">
                    <button className="btn btn-primary">
                        <span className="send-excel"></span>
                        <CSVLink data={props.historyData}> Export to CSV</CSVLink>

                    </button>
                </div>
            </div>
            <div className="rr-list-content">
                <div className="r-table-wrapper">
                    {props.historyData.length ? <table className="table">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Message</th>
                                <th>Updated Date</th>
                            </tr>
                        </thead>
                        <tbody>

                            {props.historyData.map((field: any) => (
                                <tr>
                                    <td>{field.nameOfUser}</td>
                                    <td>{field.changeMessage}</td>
                                    <td> {moment(field.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                </tr>

                            ))}


                        </tbody>
                    </table> : <div>No data available</div>}

                </div>
                <div className="table-footer-content"></div>
            </div>
        </div >
    )
}
export default HistoryTab