import React, { useState, useEffect } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { get, put } from "../../../../types/api";
import Loader from "../../../shared/Loader/Loader";
import { rightPanelOptions } from "../TimecardConstants";

const AllowancePanel = (props: any) => {
  const [allowanceCurrData, setCurrAllowance] = useState(
    [] as any
  );

  const [allowanceClicked, setAllowanceClicked] = useState({index: -1, field: ""});
  const [allowanceLoader, setAllowanceLoader] = useState(false);

  useEffect(() => {
    getAllowanceByCrewId(props.selectedEmployee.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedEmployee.id]);

  /** API : GET allowance by crew id */
  const getAllowanceByCrewId = async (crewId: any) => {
    try {
      setAllowanceLoader(true);
      let url = `/v1/api/time-card/helper/available-allowances?crewId=${crewId}`;
      const { data } = await get(url);
      if (data && data.data && data.data.length) {
        setCurrAllowance(data.data.map((eachCurrAllowance: { allowanceAmount: any; notes: any; }) => {
          return {
            ...eachCurrAllowance,
            amount: eachCurrAllowance.allowanceAmount,
            note: eachCurrAllowance.notes,
          }
        }));
        // setAllowancesOfCrew([...data.data]);
      }
    } catch (error) {
      console.log(error);
      setCurrAllowance([]);
      setAllowanceClicked({index: -1, field: ""});
      // setAllowancesOfCrew([]);
    } finally {
      setAllowanceLoader(false);
    }
  };

  // API to save allowance
  const saveAllowance = async () => {
    try {
      const url = `/v1/api/time-card/helper/allowances`;
      
      const allowances = [] as any;
      Object.keys(props.selectedAllowanceCells).forEach((key: any) => {
        const allowanceCell = props.selectedAllowanceCells[key];
        const employee = allowanceCell.employee;
        allowanceCurrData.forEach((eachCurrAllowance: any) => {
          const data  = {
            ...eachCurrAllowance,
            allowanceAmount: eachCurrAllowance.amount,
            notes: eachCurrAllowance.note,
            timeCardCrewId: employee.id,
            _allowanceTypeColor: eachCurrAllowance._allowanceTypeColor || "#314860"
          }
          delete data.amount
          delete data.note
          allowances.push(data);
        })
      });
      const payload = {
        allowances,
      };
      const res = await put(url, payload);
      if (res.status === 200) {
        props.setSelectedAllowanceCells({});
        props.getTimecardById();
      }
      console.log(url, payload);
    } catch (error: any) {
      console.log(error.response);
    }
  };

  return (
    <>
      <div className="table-filling-wrapper">
        {allowanceLoader ? <Loader showComponentLoader={true} /> : null}
        <form className="tf-content">
          <div className="tf-header">
            {/* Allowances Start */}
            <h5 className="tf-title">Allowances</h5>
            {/* Allowances End */}

            {/* eslint-disable-next-line */}
            <a
              // eslint-disable-next-line
              href="javascript:void(0);"
              onClick={() => {
                props.toggleRightPanel({
                  ...rightPanelOptions,
                  isAllowanceOpened: false,
                });
                setCurrAllowance([]);
                props.setSelectedAllowanceCells({});
                setAllowanceClicked({index: -1, field: ""});
              }}
              className="tf-close"
            ></a>
          </div>
          <div className="tf-body">
            {/* Allowances Start */}
            {allowanceCurrData.map((eachCurrAllowance: any, index: number) => {
              return <>
                <div className="form-group">
                  <input
                    id={`allowanceAmount-${index}`}
                    type="number"
                    min="0"
                    className="form-control"
                    placeholder="Enter Amount"
                    pattern="^[+-]?[0-9]{1,4}(?:\.[0-9]{1,2})?$"
                    onWheel={ event => event.currentTarget.blur() }
                    aria-describedby="Allowance Amount"
                    value={eachCurrAllowance.amount ? eachCurrAllowance.amount : 0}
                    onClick={() =>
                      setAllowanceClicked({ index, field: "amount" })
                    }
                    onSelect={() =>
                      setAllowanceClicked({ index, field: "amount" })
                    }
                    onFocus={e => e.target.select()}
                    onChange={(e) => {
                      const value = e.target.value
                      const tempData = allowanceCurrData
                      tempData[allowanceClicked.index][allowanceClicked.field] = parseFloat(value) * 1;
                      setCurrAllowance([ ...tempData ]);
                    }}
                    required
                  />
                  <label className="text-label" htmlFor={`allowanceAmount-${index}`}>
                    {eachCurrAllowance._allowanceTypeName} - {eachCurrAllowance._calcTypeLabel} Amount
                  </label>
                </div>
                <div className="form-group">
                  <textarea
                    rows={3}
                    id={`allowanceNote-${index}`}
                    className="form-control"
                    placeholder="Enter Notes"
                    aria-describedby="Allowance Note"
                    onClick={() =>
                      setAllowanceClicked({ index, field: "note" })
                    }
                    onSelect={() => setAllowanceClicked({ index, field: "note" })}
                    value={eachCurrAllowance.note ? eachCurrAllowance.note : ""}
                    onChange={(e) => {
                      const tempData = allowanceCurrData
                      tempData[allowanceClicked.index][allowanceClicked.field] = e.target.value
                      setCurrAllowance([ ...tempData ]);
                    }}
                    required
                  ></textarea>
                  <label className="text-label" htmlFor={`allowanceNote-${index}`}>
                    {eachCurrAllowance._allowanceTypeName} - {eachCurrAllowance._calcTypeLabel} Note
                  </label>
                </div>
              </>
            })}
          </div>

          {/* Allowances End */}
          <div className="tf-footer d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="btn btn-secondary mr-3"
              onClick={() => {
                props.toggleRightPanel({
                  ...rightPanelOptions,
                  isAllowanceOpened: false,
                });
                setCurrAllowance([]);
                props.setSelectedAllowanceCells({});
                setAllowanceClicked({index: -1, field: ""});
              }}
            >
              Cancel{" "}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                props.toggleRightPanel({
                  ...rightPanelOptions,
                  isAllowanceOpened: false,
                });
                setAllowanceClicked({index: -1, field: ""});
                saveAllowance();
              }}
            >
              Save{" "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AllowancePanel;
