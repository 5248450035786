import React, { useState, useEffect } from "react";
import Header from "../../shared/Header/Header";
import SelectDropdown from "../../shared/SelectDropdown/SelectDropdown";
import DateSelector from "../../shared/DateSelector/DateSelector";
import { get, post } from "../../../types/api";
import {
  useServiceStore,
  useServerConstants,
  useServiceContractStore,
  useTimeCardStore,
} from "../../../store";
import moment from "moment";
import PaginatedItems from "../../shared/Pagination/Pagination";
import Loader from "../../shared/Loader/Loader";
import { useHistory } from "react-router-dom";
import { ReactComponent as ImgAccordian } from "../../../assets/images/back.svg";
import { IServiceResultItem } from "./ServiceContractInterface";
import "react-dropzone-uploader/dist/styles.css";
import ServiceTicketCreate from "../ServiceTickets/ServiceTicketCreate";
import ServiceContractCreate from "./ServiceContractCreate/ServiceContractCreate";
import shallow from "zustand/shallow";
import MultiSelect from "../../shared/MultiSelect";

const ServiceContracts = ({ ...props }) => {
  let { contract: contractAccess, ticket: ticketAccess } = props.access;

  const [serviceContractValues, setServiceContractValues] = useState({
    startDate: null,
    endDate: null,
    assignedFields: [] as any,
    contractDate: null,
    id: 0,
    projectLocationId: 0,
    status: 0,
    projectId: "" as any,
    note: "",
    contractValue: "" as any,
    filePath: [] as any,
  });
  const [dateRange, setDateRange] = useState([null as any, null as any]);
  const { selectedProject, serviceValue, setSelectedProject, setServiceValue } =
    useServiceContractStore((serviceContractStore) => serviceContractStore);
  const [createdByMailId, setCreatedBy] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [editContract, setEditContract] = useState(false);
  const [contractDropdownOptions, setContractDropdownOptions] = useState({
    projects: [],
    worklocation: [],
    availableFields: [] as any,
    jobNumbers: [],
  });

  const [currentPageContract, setCurrentPageContract] = useState(0);
  const [pageCountContract, setPageCountContract] = useState(0);
  const [pageLimitContract, setPageLimitContract] = useState(25); //25

  const [currentPageServiceItem, setCurrentPageServiceItem] = useState(0);
  const [pageCountServiceItem, setPageCountServiceItem] = useState(0);
  const [pageLimitServiceItem, setPageLimitServiceItem] = useState(25);

  const [startDate, endDate] = dateRange;
  const { companyId } = useServiceStore.getState();
  const { status } = useServerConstants.getState();

  const [modal, setModal] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [activeAccIndex, setActiveAccIndex] = useState("");
  const [acccordianData, setacccordianData] = useState([]);

  const toggle = () =>
    setModal(() => {
      return !modal;
    });

  const [contractGrouping, setContractGrouping] = useState(false);

  const getContractsData = async (index: any, projectId: any) => {
    if (projectId) {
      setLoading(true);
      setContractGrouping(false);
      // -------------Start of Api call for Grouping---------------

      const data = {
        orgEntityId: companyId,
        projectIds: [projectId],
        createdByUserIds: [],
        fromStartDate: "",
        toEndDate: "",
        fromContractDate: startDate || "",
        toContractDate: endDate || "",
        page: currentPageServiceItem,
        limit: pageLimitServiceItem,
      };
      let apiURL = `/v1/api/service/contract/list`;
      // @ts-ignore
      const res = await post(apiURL, data);
      if (res.data?.data?.serviceContracts) {
        setPageCountServiceItem(
          Math.ceil(res.data?.data?.totalResults / pageLimitServiceItem)
        );
        setacccordianData(res.data?.data?.serviceContracts || []);
        setContractGrouping(true);
        setLoading(false);
      }
    } else {
      setContractGrouping(true);
    }
  };

  const renderGraph = (id: any, projId: any) => {
    getContractsData(id, projId);
  };

  const toggleGrouping = async (
    pageChange?: boolean,
    index: any = 0,
    projectId: any = acccordianData[0]["projectId"] || ""
  ) => {
    if (pageChange || !contractGrouping) {
      setActiveAccIndex(index);
      setLoading(true);
      getContractsData(index, projectId);
      setLoading(false);
    } else if (contractGrouping) {
      setActiveAccIndex("-1");
      setContractGrouping(false);
    }

    // -------------END of Api call for Grouping---------------
  };

  const [serviceTicketProps, setServiceTicketProps] = useState({
    projectId: 0,
    serviceContractId: 0,
  });

  // @ts-ignore
  useEffect(() => {
    getCreateContract();
    getContracts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // @ts-ignore
  useEffect(() => {
    getContracts();
  }, [currentPageContract]); // eslint-disable-line react-hooks/exhaustive-deps

  // @ts-ignore
  useEffect(() => {
    if (activeAccIndex !== "") {
      getContractsData(activeAccIndex, acccordianData[0]["projectId"]);
    }
  }, [currentPageServiceItem, pageLimitServiceItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const { projectsAndSupervisors } = useTimeCardStore(
    (state) => ({ projectsAndSupervisors: state.projectsAndSupervisors }),
    shallow
  );
  
  const getCreateContract = async (id?: Number) => {
    setLoading(true);
    let apiURL = `/v1/api/service/contract/loadcreatecontract`;
    if (companyId) {
      apiURL += `?companyId=${companyId}`;
      if (id || serviceContractValues.projectId) {
        apiURL += `&projectId=${id || serviceContractValues.projectId}`;
      }
    }
    // @ts-ignore
    const res = await get(apiURL);
    setContractDropdownOptions({
      ...contractDropdownOptions,
      worklocation: res.data.worklocation,
      availableFields: res.data.availableFields,
    });
    console.log("contractDropdownOptions res: ", res);
    setLoading(false);
  };

  const getContracts = async () => {
    setLoading(true);
    let apiURL = `/v1/api/service/contract/grouplisting`;
    if (companyId) {
      apiURL += `?companyId=${companyId}`;
    }
    const payload = {
      orgEntityId: companyId,
      projectIds: selectedProject,
      createdByUserIds: [],
      fromStartDate: "",
      toEndDate: "",
      fromContractDate: startDate || "",
      toContractDate: endDate || "",
      page: currentPageContract,
      limit: pageLimitContract,
    };
    // @ts-ignore
    const res = await post(apiURL, payload);

    //--------------------START For Testing SHOULD BE REMOVED------------------------
    // const data = { ...res.data };
    // data.result = [...res.data.result, ...res.data.result, ...res.data.result]
    // data.totalResults = 3;
    // setServiceValue(data);
    // setPageCountContract(Math.ceil(data.totalResults / pageLimitContract))
    //--------------------END For Testing  SHOULD BE REMOVED------------------------

    setContractDropdownOptions({
      ...contractDropdownOptions,
      projects: res.data.filters.projects,
    });
    setServiceValue(res.data);
    setPageCountContract(Math.ceil(res.data.totalResults / pageLimitContract));
    setLoading(false);
  };

  const handlePageChangeAtPageLevel = (page: number) => {
    setCurrentPageContract(page);
  };

  const handlePageChangeAtIteamsLevel = (page: number) => {
    setCurrentPageServiceItem(page);
  };

  const serviceItems = serviceValue.result?.map(
    (resultData: IServiceResultItem, index: any) => {
      return (
        <div
          className="sc-list-content"
          key={resultData.projectDisplayNumber + index}
        >
          <div className="r-card r-card-outer">
            <div
              className={
                "r-card-header " +
                (contractGrouping && index === activeAccIndex
                  ? "has-accordian"
                  : "")
              }
              onClick={() =>
                toggleGrouping(false, index, resultData?.projectId)
              }
            >
              <h3 className="contract-group-title">
                {resultData.projectId} - {resultData.projectName}
              </h3>
              {/* eslint-disable-next-line */}
              <a href="javascript:void(0);" className="contract-group-arrow">
                <ImgAccordian />
              </a>
            </div>
            {contractGrouping && (
              <div className="sc-list-content r-card-body">
                <div className="contract-wrapper">
                  {activeAccIndex === index
                    ? acccordianData.map((serviceItem: any, index) => {
                        return (
                          <div
                            className="r-card"
                            key={`acccordian-${serviceItem.projectId}-${serviceItem.id}`}
                          >
                            <div
                              className="r-card-header"
                              key={serviceItem.id + index}
                            >
                              <h4 className="contract-title">
                                {serviceItem.serviceContractDisplayNumber}
                              </h4>
                              <span className="contract-status status-open">
                                {/* @ts-ignore */}
                                {status[serviceItem.status]}{" "}
                              </span>
                              <span className="contract-created">
                                -- created by{" "}
                                <b>{serviceItem.createdByUserName}</b> on{" "}
                                <b>
                                  {moment
                                    .utc(serviceItem.created_at)
                                    .format("MM/DD/YYYY")}
                                </b>
                              </span>
                              <div className="contract-btns ml-auto">
                                <button
                                  className="btn btn-secondary"
                                  disabled={!contractAccess.update}
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    history.push(
                                      `/servicecontractsetup/${
                                        serviceItem.id
                                      }/${serviceItem.projectId}?cnum=${
                                        serviceItem.serviceContractDisplayNumber.split(
                                          "-"
                                        )[1]
                                      }`
                                    );
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  disabled={!ticketAccess.create}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setServiceTicketProps({
                                      projectId: serviceItem.projectId,
                                      serviceContractId: serviceItem.id,
                                    });
                                    setServiceModal(true);
                                  }}
                                >
                                  Create Service Ticket
                                </button>
                              </div>
                            </div>
                            <div className="r-card-body">
                              <div className="row">
                                <div className="col-7">
                                  <div className="contract-details">
                                    <div className="text-label-value">
                                      <div className="text-label">
                                        Project Name
                                      </div>
                                      <div className="text-value">
                                        {resultData.projectName}
                                      </div>
                                    </div>
                                    <div className="text-label-value">
                                      <div className="text-label">
                                        Worksite Location
                                      </div>
                                      <div className="text-value">
                                        {serviceItem.projectLocationName}
                                      </div>
                                    </div>
                                    <div className="text-label-value">
                                      <div className="text-label">
                                        Contract Date
                                      </div>
                                      <div className="text-value">
                                        {moment
                                          .utc(serviceItem.contractDate)
                                          .format("MM/DD/YYYY")}
                                      </div>
                                    </div>
                                    <div className="text-label-value">
                                      <div className="text-label">Duration</div>
                                      <div className="text-value">
                                        {moment
                                          .utc(serviceItem.startDate)
                                          .format("MM/DD/YYYY")}{" "}
                                        --{" "}
                                        {moment
                                          .utc(serviceItem.endDate)
                                          .format("MM/DD/YYYY")}
                                      </div>
                                    </div>
                                    <div className="text-label-value">
                                      <div className="text-label">Value</div>
                                      <div className="text-value">
                                        $ {serviceItem.contractValue}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="contract-details">
                                    <div className="text-label-value">
                                      <div className="text-label">
                                        Description
                                      </div>
                                      <div className="text-value">
                                        {serviceItem.note}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-5">
                                  <div className="contract-details legends-section">
                                    <div className="text-label-value">
                                      <div className="text-label label-total">
                                        Total Service Tickets
                                      </div>
                                      <div className="text-value">
                                        {serviceItem.serviceTicketStatus?.total}
                                      </div>
                                    </div>
                                    <div className="text-label-value">
                                      <div className="text-label label-draft">
                                        Draft
                                      </div>
                                      <div className="text-value">
                                        {serviceItem.serviceTicketStatus?.draft}
                                      </div>
                                    </div>
                                    <div className="text-label-value">
                                      <div className="text-label label-open">
                                        Open
                                      </div>
                                      <div className="text-value">
                                        {serviceItem.serviceTicketStatus?.open}
                                      </div>
                                    </div>
                                    <div className="text-label-value">
                                      <div className="text-label label-inprogress">
                                        In Progress
                                      </div>
                                      <div className="text-value">
                                        {
                                          serviceItem.serviceTicketStatus
                                            ?.inProgress
                                        }
                                      </div>
                                    </div>
                                    <div className="text-label-value">
                                      <div className="text-label label-done">
                                        Done
                                      </div>
                                      <div className="text-value">
                                        {serviceItem.serviceTicketStatus?.done}
                                      </div>
                                    </div>
                                    <div className="text-label-value">
                                      <div className="text-label label-closed">
                                        Closed
                                      </div>
                                      <div className="text-value">
                                        {
                                          serviceItem.serviceTicketStatus
                                            ?.closed
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <div className="contract-details">
                                    <div className="chart-wrapper">
                                      <div className="chart-content">
                                        {serviceItem.serviceTicketStatus
                                          ?.total ? (
                                          <>
                                            {serviceItem.serviceTicketStatus
                                              ?.draft ? (
                                              <div
                                                className="chart-status status-draft"
                                                style={{
                                                  width: `${
                                                    (serviceItem
                                                      .serviceTicketStatus
                                                      ?.draft *
                                                      100) /
                                                    serviceItem
                                                      .serviceTicketStatus
                                                      ?.total
                                                  }%`,
                                                }}
                                              ></div>
                                            ) : (
                                              ""
                                            )}
                                            {serviceItem.serviceTicketStatus
                                              ?.open ? (
                                              <div
                                                className="chart-status status-open"
                                                style={{
                                                  width: `${
                                                    (serviceItem
                                                      .serviceTicketStatus
                                                      ?.open *
                                                      100) /
                                                    serviceItem
                                                      .serviceTicketStatus
                                                      ?.total
                                                  }%`,
                                                }}
                                              ></div>
                                            ) : (
                                              ""
                                            )}
                                            {serviceItem.serviceTicketStatus
                                              ?.inProgress ? (
                                              <div
                                                className="chart-status status-inprogress"
                                                style={{
                                                  width: `${
                                                    (serviceItem
                                                      .serviceTicketStatus
                                                      ?.inProgress *
                                                      100) /
                                                    serviceItem
                                                      .serviceTicketStatus
                                                      ?.total
                                                  }%`,
                                                }}
                                              ></div>
                                            ) : (
                                              ""
                                            )}
                                            {serviceItem.serviceTicketStatus
                                              ?.done ? (
                                              <div
                                                className="chart-status status-done"
                                                style={{
                                                  width: `${
                                                    (serviceItem
                                                      .serviceTicketStatus
                                                      ?.done *
                                                      100) /
                                                    serviceItem
                                                      .serviceTicketStatus
                                                      ?.total
                                                  }%`,
                                                }}
                                              ></div>
                                            ) : (
                                              ""
                                            )}
                                            {serviceItem.serviceTicketStatus
                                              ?.closed ? (
                                              <div
                                                className="chart-status status-closed"
                                                style={{
                                                  width: `${
                                                    (serviceItem
                                                      .serviceTicketStatus
                                                      ?.closed *
                                                      100) /
                                                    serviceItem
                                                      .serviceTicketStatus
                                                      ?.total
                                                  }%`,
                                                }}
                                              ></div>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            )}
            {activeAccIndex === index && resultData.serviceContracts?.length ? (
              <PaginatedItems
                pageCount={pageCountServiceItem}
                currentPage={currentPageServiceItem}
                pageLimit={pageLimitServiceItem}
                setPageLimit={setPageLimitServiceItem}
                setCurrentPage={setCurrentPageServiceItem}
                itemsPerPage={pageLimitServiceItem}
                handlePageChange={handlePageChangeAtIteamsLevel}
                customClass="font-l"
              />
            ) : null}
          </div>
        </div>
      );
    }
  );

  return (
    <div className="service-contracts-wrapper">
      {loading && <Loader></Loader>}
      <Header breadcrumb={"Service Contracts"} title={"Service Contracts"} />
      <div className="inapp-filter-wrapper">
        {/* {loading && <Loader showComponentLoader={true}></Loader>} */}
        <div className="inapp-filters">
          <h2 className="page-title-sub">Search By</h2>
          <form className="contractfilter-form">
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <div
                    className={
                      "multi-select-wrap " +
                      (selectedProject && selectedProject.length > 0
                        ? "is-selected"
                        : "")
                    }
                  >
                    <MultiSelect
                      items={projectsAndSupervisors.projects?.map((e: any) => {
                        return { label: e.projectDisplayNameUI, value: e.id, chipValue: e.projectNumber };
                      }) || []}
                      selectedValues={[]}
                      noOptionsText="No Projects"
                      disabledInput={!contractAccess.read}
                      label="Projects"
                      placeholder="Search Projects"
                      selectAllLabel="Select all"
                      onChange={(e: any) => {
                        setSelectedProject(e.map((x: any) => x.value));
                        setCreatedBy("");
                        setDateRange([null, null]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <SelectDropdown
                    options={serviceValue.filters?.users}
                    label={"Created By"}
                    selected={createdByMailId || []}
                    disabled={!contractAccess.read}
                    onChange={(e: any) =>
                      e ? setCreatedBy(e) : setCreatedBy("")
                    }
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  {/* @ts-ignore */}
                  <DateSelector
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    selected={serviceContractValues.endDate}
                    onChange={(update: any) => {
                      // removing timezone offset
                      setDateRange([
                        update[0]
                          ? new Date(
                              update[0].getTime() -
                                update[0].getTimezoneOffset() * 60000
                            )
                          : null,
                        update[1]
                          ? new Date(
                              update[1].getTime() -
                                update[1].getTimezoneOffset() * 60000
                            )
                          : null,
                      ]);
                    }}
                    disabled={!contractAccess.read}
                    isClearable={false}
                    placeholderText="Select a Date Range"
                    labelText="Contract Date"
                  />
                </div>
              </div>
              <div className="col-1">
                <div className="form-group submit-filter">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!contractAccess.read}
                    onClick={(e) => {
                      if (activeAccIndex !== "-1") {
                        setActiveAccIndex("-1");
                        setContractGrouping(false);
                      }
                      getContracts();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="sc-list-wrapper">
        <div className="sc-list-heading d-flex align-items-center justify-content-between">
          <h3 className="heading-sub">
            Contract List{!loading && `(${serviceValue.result?.length})`}
          </h3>
          <div className="sc-list-btns">
            <button
              className="btn btn-primary"
              disabled={!contractAccess.create}
              onClick={(e: any) => {
                e.preventDefault();
                setServiceContractValues({
                  ...serviceContractValues,
                  id: 0,
                  projectLocationId: 0,
                  status: 0,
                  // assignedFields: serviceItem.assignedFields,
                  // @ts-ignore
                  projectId: "",
                  note: "",
                  contractValue: "",
                  // @ts-ignore
                  endDate: null,
                  // @ts-ignore
                  startDate: null,
                  // @ts-ignore
                  contractDate: null,
                });
                setEditContract(false);
                toggle();
              }}
            >
              Add New Contract
            </button>
          </div>
        </div>
        {!loading && serviceValue.result?.length ? serviceItems : ""}
        {serviceValue.result?.length === 0 ? (
          <span className="no-results-contract">
            Please select atleast one valid filter
          </span>
        ) : (
          ""
        )}
        {serviceValue.result?.length ? (
          <div className="table-footer-content">
            <PaginatedItems
              pageCount={pageCountContract}
              currentPage={currentPageContract}
              pageLimit={pageLimitContract}
              setPageLimit={setPageLimitContract}
              setCurrentPage={setCurrentPageContract}
              itemsPerPage={pageLimitContract}
              handlePageChange={handlePageChangeAtPageLevel}
            />
          </div>
        ) : null}
      </div>

      {modal ? (
        <ServiceContractCreate
          toggle={toggle}
          modal={modal}
          setModal={setModal}
          editContract={editContract}
        />
      ) : (
        ""
      )}
      {serviceModal && (
        <ServiceTicketCreate
          toggle={() => setServiceModal(!serviceModal)}
          modal={serviceModal}
          projectId={serviceTicketProps.projectId}
          serviceContractId={serviceTicketProps.serviceContractId}
          updateDropdown={() =>
            renderGraph(activeAccIndex, serviceTicketProps.projectId)
          }
        />
      )}
    </div>
  );
};

export default ServiceContracts;
