import type { FC } from "react";
import { useDrag } from "react-dnd";

export const ServiceContractCreateAvailableFields: FC<any> =
  function ServiceContractCreateAvailableFields({ field, type }) {
    const [{ opacity }, drag] = useDrag(
      () => ({
        type,
        item: { field: field },
        collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0.4 : 1,
        }),
      }),
      [field, type]
    );

    return (
      <div
        className="add-tag"
        ref={drag}
        style={{ opacity }}
        data-testid={`box`}
      >
        {field.name || field.fieldName}
      </div>
    );
  };
