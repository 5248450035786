import React, { useState, useEffect } from "react";
import Joi from 'joi'
import SelectDropdown from "../../shared/SelectDropdown/SelectDropdown";
import Loader from "../../shared/Loader/Loader";
import { get, post, put } from "../../../types/api";
import { useServiceStore, useServerConstants, useTimeCardStore, useServiceTicketStore } from "../../../store";
import shallow from "zustand/shallow";
import { States } from "../../../components/shared/Constants";
import TextareaAutosize from "@mui/material/TextareaAutosize";

interface IServiceTicketCreateComponentProps {
  toggle: (refreshPage?: boolean) => void;
  modal: boolean;
  projectId?: number;
  serviceContractId?: number;
  serviceTicketId?: number;
  updateDropdown?: any
  postUpdate?: any;
  existingServiceValues?: IServiceValue;
}

export interface IDropdownTemplate {
  name?: string;
  id?: number
}

interface INewServiceTicketTemplate {
  id?: number;
  selectedProject: IDropdownTemplate;
  selectedContract: IDropdownTemplate;
  workOrder: string;
  poleNumber: string;
  city: string;
  stNotes: string;
  additionalInfo: string;
  poNumber?: string;
  addressCrossStreet?: string;
  selectedTicketStatus: IDropdownTemplate;
  state: IDropdownTemplate;
}

export interface IServiceValue {
  projects: IDropdownTemplate[];
  serviceContracts: IDropdownTemplate[];
  ticketStatus: IDropdownTemplate[];
}

interface IErrorFields {
  selectedProject?: boolean
  selectedContract?: boolean
  selectedTicketStatus?: boolean
  workOrder?: boolean
  poleNumber?: boolean
  state?: boolean
}

const ServiceTicketCreate = (props: IServiceTicketCreateComponentProps) => {
  // STATE DECLARATION
  // fetching companyId from global state
  const companyId = useServiceStore(serviceStore => serviceStore.companyId);
  const constants = useServerConstants(serverConstants => serverConstants.constants)
  const { setSelectedProjectId } = useServiceTicketStore.getState();

  // loader states
  const [modalLoader, setModalLoader] = useState(false)
  const [dropdownLoader, setDropdownLoader] = useState(false)
  const tokenDetails = useServiceStore(
    (serviceStore) => serviceStore.tokenDetails
  );
  // dropdown values
  const [serviceValue, setServiceValue] = useState({
    projects: [],
    serviceContracts: [],
    ticketStatus: []
  } as IServiceValue);
  const states = JSON.parse(JSON.stringify(States));

  const { projectsAndSupervisors } = useTimeCardStore(
    (state) => ({ projectsAndSupervisors: state.projectsAndSupervisors }),
    shallow
  );

  // states for creating new service ticket
  const newServiceTicketTemplate: INewServiceTicketTemplate = {
    id: 0,
    selectedProject: { name: '', id: undefined },
    selectedContract: { name: '', id: undefined },
    workOrder: "",
    poleNumber: "",
    stNotes: "",
    additionalInfo: "",
    selectedTicketStatus: { name: 'OPEN', id: 1 },
    state: { name: '', id: undefined },
    poNumber: "",
    addressCrossStreet: "",
    city: "",
  }
  const [newServiceTicketFields, setNewServiceTicketFields] = useState(newServiceTicketTemplate)
  const [fieldError, setFieldError] = useState<IErrorFields>({})

  // VALIDATIONS
  const newServiceTicketFieldsSchema = Joi.object({
    selectedProject: Joi.object({
      id: Joi.number().required()
    }).unknown(),
    selectedContract: Joi.object({
      id: Joi.number().required()
    }).unknown(),
    selectedTicketStatus: Joi.object({
      id: Joi.number().required()
    }).unknown(),
    state: Joi.object({
      name: Joi.string().required()
    }).unknown(),
    workOrder: Joi.string().required(),
    poleNumber: Joi.string().required(),
    city: Joi.string()
  }).unknown()

  const checkForValidations = () => {
    const { error } = newServiceTicketFieldsSchema.validate(newServiceTicketFields, { abortEarly: false })
    let errorFields: { [key: string]: boolean } = {}
    error?.details.forEach((eachErrorDetail) => {
      errorFields[eachErrorDetail.path[0]] = true
    })
    setFieldError(errorFields)
    return error
  }

  // SETTER/GETTER METHODS
  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event?.preventDefault();
    const error = checkForValidations()
    console.log(error);

    if (!error) {
      const myPromise = new Promise((resolve, reject) => {
        resolve(createUpdateTicketApi());
      });
      await myPromise.then((res) => {
        // Post update operations
        if (props.updateDropdown) {
          props.updateDropdown(newServiceTicketFields)
        }
        if (props.postUpdate) {
          props.postUpdate(res)
        }
      })
    }
  }

  // API CALLING
  // fetch create service ticket details
  const getCreateTicketApi = async () => {
    let url = `/v1/api/service/ticket/loadcreateticket?companyId=${companyId}`;
    if (props.serviceTicketId) {
      url = props.projectId ? `${url}&projectId=${props.projectId}` : url;
    } else {
      url = newServiceTicketFields.selectedProject.id ? `${url}&projectId=${newServiceTicketFields.selectedProject.id}` : url;
    }
    setDropdownLoader(true)
    const { data } = await get(url);
    setDropdownLoader(false)
    setServiceValue((prevServiceValue) => {
      return {
        ...prevServiceValue,
        projects: data.projects || prevServiceValue.projects,
        serviceContracts:
          data.serviceContract || prevServiceValue.serviceContracts,
      };
    });
  };

  // create/update service ticket
  const createUpdateTicketApi = async () => {
    let url = `/v1/api/service/ticket`;
    url = newServiceTicketFields.id
      ? `${url}/id/${newServiceTicketFields.id}`
      : url;
    // creating payload for create/update

    const payload = {
      id: newServiceTicketFields.id,
      tenantId: tokenDetails?.tenantId,
      serviceContractId: newServiceTicketFields.selectedContract.id,
      serviceTicketName: "service ticket",
      workOrderNumber: newServiceTicketFields.workOrder,
      poleNumber: newServiceTicketFields.poleNumber,
      city: newServiceTicketFields.city,
      poNumber: newServiceTicketFields.poNumber,
      addressCrossStreet: newServiceTicketFields.addressCrossStreet,
      ticketStatus: newServiceTicketFields.selectedTicketStatus.id,
      state: newServiceTicketFields.state.name,
      stNotes: newServiceTicketFields.stNotes || '',
      additionalInfo: newServiceTicketFields.additionalInfo || '',
    };
    setModalLoader(true)
    const res = newServiceTicketFields.id ? await put(url, payload) : await post(url, payload);
    setModalLoader(false)
    if (props.updateDropdown) {
      await props.updateDropdown(newServiceTicketFields)
    }
    res.status === 200 && props.toggle();
    setNewServiceTicketFields(newServiceTicketTemplate);
    setSelectedProjectId(newServiceTicketFields.selectedProject.id);
    return res.data;
  };

  // get service ticket details
  const getServiceTicketApi = async () => {
    try {
      let url = `/v1/api/service/ticket/id/${props.serviceTicketId}`
      setModalLoader(true)
      const { data: serviceTicketDetails } = await get(url);
      setModalLoader(false)
      // get selected project details 
      let selectedProjectDetails = {};
      if (props.projectId) {
        selectedProjectDetails = projectsAndSupervisors.filteredProjects.find((e: any) => e.id === props.projectId) || {};
      }
      // setting service ticket details
      // setting selected project
      // setting selected contract id but not name
      // setting selected lineman id but not name
      setNewServiceTicketFields({
        id: props.serviceTicketId,
        selectedProject: { name: '', id: props.projectId, ...selectedProjectDetails },
        selectedContract: { name: '', id: serviceTicketDetails.serviceContractId },
        workOrder: serviceTicketDetails.workOrderNumber,
        poleNumber: serviceTicketDetails.poleNumber,
        city: serviceTicketDetails.city,
        poNumber: serviceTicketDetails.poNumber,
        addressCrossStreet: serviceTicketDetails.addressCrossStreet,
        stNotes: serviceTicketDetails.stNotes,
        additionalInfo: serviceTicketDetails.additionalInfo,
        selectedTicketStatus: { name: '', id: serviceTicketDetails.ticketStatus },
        state: { name: serviceTicketDetails.state, id: states.find((x: any) => x.name === serviceTicketDetails.state) }
      })
    } catch (error) {
      console.log(error);
    }
  }

  // LIFECYCLE METHODS
  // get create ticket api is being called
  // when companyId or projectId changes
  useEffect(() => {
    if (companyId) {
      getCreateTicketApi();
    }
  }, [companyId, newServiceTicketFields.selectedProject.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Edit flow: get service ticket details
  // fetch ticket details 
  useEffect(() => {
    setNewServiceTicketFields(newServiceTicketTemplate)
    if (props.serviceTicketId) {
      getServiceTicketApi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceTicketId])

  useEffect(() => {
    // Edit flow
    // setting selected contract and selected lineman name, when id is already set
    // used for populating the dropdowns while editing the ticket details
    if (props.serviceTicketId && newServiceTicketFields.selectedContract.id && !newServiceTicketFields.selectedContract.name) {
      setNewServiceTicketFields(prevNewServiceFields => {
        return {
          ...newServiceTicketFields,
          selectedContract: serviceValue.serviceContracts.find((eachContract) => (eachContract.id === prevNewServiceFields.selectedContract.id)) || prevNewServiceFields.selectedContract,
          selectedTicketStatus: serviceValue.ticketStatus.find((eachTicket) => (eachTicket.id === prevNewServiceFields.selectedTicketStatus.id)) || prevNewServiceFields.selectedTicketStatus
        }
      })
    }
    // Create service ticket from service contract list
    // setting selected contract and selected project name, when id is already set
    // used for populating the dropdowns while creating the service ticket from service contract
    if (props.serviceContractId && newServiceTicketFields.selectedContract.id && !newServiceTicketFields.selectedContract.name) {
      setNewServiceTicketFields(prevNewServiceFields => {
        return {
          ...newServiceTicketFields,
          // selectedProject: serviceValue.projects.find((eachProject) => (eachProject.id === prevNewServiceFields.selectedProject.id)) || prevNewServiceFields.selectedProject,
          selectedContract: serviceValue.serviceContracts.find((eachContract) => (eachContract.id === prevNewServiceFields.selectedContract.id)) || prevNewServiceFields.selectedContract
        }
      })
    }

    if (Object.keys(fieldError).length) {
      checkForValidations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceValue])

  // setting ticket status dropdown values using global state server constants
  useEffect(() => {
    if (constants.serviceContract?.CT.status) {
      setServiceValue({
        ...serviceValue,
        ticketStatus: Object.entries(constants.serviceContract?.CT.status).map(([name, id]: [string, number]) => ({ id, name }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constants])

  useEffect(() => {
    if (props.projectId && props.serviceContractId) {
      // get selected project details 
      let selectedProjectDetails = {};
      if (props.projectId) {
        selectedProjectDetails = projectsAndSupervisors.filteredProjects.find((e: any) => e.id === props.projectId) || {};
      }
      setNewServiceTicketFields({
        ...newServiceTicketFields,
        selectedProject: { id: props.projectId, name: '', ...selectedProjectDetails },
        selectedContract: { id: props.serviceContractId, name: '' }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectId, props.serviceContractId])

  // mounting of the component
  useEffect(() => {
    if (props.existingServiceValues) {
      setServiceValue(props.existingServiceValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"create-new-sticket " + (props.modal ? 'show-sticket-popup' : '')}>
      <div className="sticket-backdrop"></div>
      <div className="sticket-content">
        <div className="sticket-header">
          <h5>{props.serviceTicketId ? "Update" : "Create"} Service Ticket</h5>
          <span className="close-icon" onClick={() => props.toggle()}></span>
        </div>
        <div className="sticket-body">
          <form className="service-create-form">
            {modalLoader && <Loader showComponentLoader={true} />}
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  {!newServiceTicketFields.selectedProject.name && dropdownLoader && <Loader showComponentLoader={true} />}
                  <SelectDropdown
                    labelKey="projectDisplayNameUI"
                    options={projectsAndSupervisors.filteredProjects}
                    label={"Project"}
                    selected={
                      newServiceTicketFields.selectedProject?.id ? [newServiceTicketFields.selectedProject] : []
                    }
                    disabled={!!props.serviceTicketId || !!props.serviceTicketId}
                    error={fieldError["selectedProject"]}
                    onChange={(e: any) => {
                      setNewServiceTicketFields({
                        ...newServiceTicketFields,
                        selectedProject:
                          e?.length && e[0]?.id
                            ? e[0]
                            : { name: e, id: undefined },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  {!newServiceTicketFields.selectedContract.name && dropdownLoader && <Loader showComponentLoader={true} />}
                  <SelectDropdown
                    labelKey="name"
                    options={serviceValue?.serviceContracts}
                    label={"Service Contract"}
                    selected={
                      newServiceTicketFields.selectedContract?.id
                        ? [newServiceTicketFields.selectedContract]
                        : []
                    }
                    disabled={!!props.serviceTicketId}
                    error={fieldError["selectedContract"]}
                    onChange={(e: any) => {
                      setNewServiceTicketFields({
                        ...newServiceTicketFields,
                        selectedContract:
                          e?.length && e[0]?.id
                            ? e[0]
                            : { name: e, id: undefined },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className={`form-group ${fieldError["workOrder"] ? 'has-error' : ''}`}>
                  <input
                    type="text"
                    className="form-control"
                    id="contractValue"
                    aria-describedby="contractValue"
                    placeholder=""
                    required
                    value={newServiceTicketFields.workOrder}
                    onChange={(e) => {
                      setNewServiceTicketFields({
                        ...newServiceTicketFields,
                        workOrder: e.target.value,
                      });
                    }}
                  />
                  {/* @ts-ignore */}
                  <label className="text-label" htmlFor="contractValue">
                    Work Order
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className={`form-group ${fieldError["poleNumber"] ? 'has-error' : ''}`}>
                  <input
                    type="text"
                    className="form-control"
                    id="contractValue"
                    aria-describedby="contractValue"
                    placeholder=""
                    required
                    value={newServiceTicketFields.poleNumber}
                    onChange={(e) => {
                      setNewServiceTicketFields({
                        ...newServiceTicketFields,
                        poleNumber: e.target.value,
                      });
                    }}
                  />
                  {/* @ts-ignore */}
                  <label className="text-label" htmlFor="contractValue">
                    Pole #
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className={`form-group`}>
                  <input
                    type="text"
                    className="form-control"
                    id="poNumber"
                    aria-describedby="poNumber"
                    placeholder=""
                    value={newServiceTicketFields.poNumber}
                    onChange={(e) => {
                      setNewServiceTicketFields({
                        ...newServiceTicketFields,
                        poNumber: e.target.value,
                      });
                    }}
                  />
                  {/* @ts-ignore */}
                  <label className="text-label" htmlFor="poNumber">
                    Purchase Order
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className={`form-group`}>
                  <input
                    type="text"
                    className="form-control"
                    id="addressCrossStreet"
                    aria-describedby="addressCrossStreet"
                    placeholder=""
                    value={newServiceTicketFields.addressCrossStreet}
                    onChange={(e) => {
                      setNewServiceTicketFields({
                        ...newServiceTicketFields,
                        addressCrossStreet: e.target.value,
                      });
                    }}
                  />
                  {/* @ts-ignore */}
                  <label className="text-label" htmlFor="addressCrossStreet">
                    Address/Cross Street
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className={`form-group`}>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    aria-describedby="city"
                    placeholder=""
                    value={newServiceTicketFields.city}
                    onChange={(e) => {
                      setNewServiceTicketFields({
                        ...newServiceTicketFields,
                        city: e.target.value,
                      });
                    }}
                  />
                  {/* @ts-ignore */}
                  <label className="text-label" htmlFor="city">
                    Town
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  {!newServiceTicketFields.selectedTicketStatus.name && dropdownLoader && <Loader showComponentLoader={true} />}
                  <SelectDropdown
                    labelKey="name"
                    options={serviceValue?.ticketStatus}
                    label={"Ticket Status"}
                    selected={
                      newServiceTicketFields.selectedTicketStatus?.id?.toString()
                        ? [newServiceTicketFields.selectedTicketStatus]
                        : []
                    }
                    error={fieldError["selectedTicketStatus"]}
                    onChange={(e: any) => {
                      setNewServiceTicketFields({
                        ...newServiceTicketFields,
                        selectedTicketStatus:
                          e?.length > 0 && e[0]?.id.toString() !== null
                            ? e[0]
                            : { name: e, id: undefined },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  {!newServiceTicketFields.state.name && dropdownLoader && <Loader showComponentLoader={true} />}
                  <SelectDropdown
                    labelKey="name"
                    options={states}
                    label={"State"}
                    selected={
                      newServiceTicketFields.state?.name?.toString()
                        ? [newServiceTicketFields.state]
                        : []
                    }
                    error={fieldError["state"]}
                    onChange={(e: any) => {
                      setNewServiceTicketFields({
                        ...newServiceTicketFields,
                        state:
                          e?.length > 0 && e[0]?.id.toString() !== null
                            ? e[0]
                            : { name: e, id: undefined },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <TextareaAutosize
                    className="form-control"
                    id="additionalInfo"
                    value={newServiceTicketFields.additionalInfo}
                    onChange={(e) => {
                      setNewServiceTicketFields({
                        ...newServiceTicketFields,
                        additionalInfo: e.target.value,
                      });
                    }}
                    aria-describedby="additionalInfo"
                    placeholder=""
                  />
                  <label className="text-label" htmlFor="additionalInfo">
                    Additional Information
                  </label>
                </div>
              </div>
            </div>
            <div className="sticket-footer">
              <button className="btn btn-secondary" onClick={() => props.toggle()}>
                Cancel
              </button>
              <button className="btn btn-primary"
                onClick={handleSubmit}>
                Save
              </button>{" "}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ServiceTicketCreate;
